<template>
    <div class="list">
      <list-search :childData="childData" @onSearch="search"  @clearSearch="search"></list-search>
      <global-table :tableField="tableField" :suptableField='suptableField' :tableData="tableData" :paginationData="paginationData" @tableAction="tableAction" @pageChange="pageChange"></global-table>
    </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { SearchList, SearchData, tableField, tableData, suptableField, supSearchList } from './js/myorder.js'
import { request } from '@/assets/js/http.js'
import dayjs from 'dayjs'
export default {
  components: Component.components,
  name: 'Myorder',
  data () {
    var pageSize = parseInt((document.documentElement.clientHeight - 280) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      childData: {
        searchList: SearchList,
        searchData: SearchData
      },
      tableField: tableField,
      suptableField: suptableField,
      tableData: tableData,
      searchdata: SearchData,
      paginationData: { },
      pageSize: pageSize,
      pageNum: 1,
      number: '',
      type: this.$store.state.loginInfo.userType // userType  1  是供应商
    }
  },

  mounted () {
    this.MyorderList()
  },
  methods: {
    MyorderList () {
      var pageSize = parseInt((document.documentElement.clientHeight - 280) / 42)
      if (pageSize < 10) {
        pageSize = 10
      }
      const obj = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      request('/api/order/order/getOrderInfoByUserId', 'POST', obj).then((res) => {
        if (res.code === '200') {
          this.tableData = res.data.ipage.records
          if (this.$store.state.loginInfo.userType !== 0) { // 供应商1
            this.tableField = suptableField
            this.childData = {
              searchList: supSearchList,
              searchData: SearchData
            }
          } else {
            this.tableField = tableField
            this.childData = {
              searchList: SearchList,
              searchData: SearchData
            }
          }
          this.paginationData = { pageSize: pageSize, total: res.data.ipage.total }
        }
      })
    },
    tableAction: function (index, data, clickBtn) {
      if (clickBtn.operationButton === 'see') {
        this.$router.push({ params: { viewName: 'OrderDetail' }, query: { orderCode: data.orderCode, id: data.id } })
      }
    },
    search: function (data) {
      data.pageNum = this.pageNum
      data.pageSize = this.pageSize
      this.searchdata = { ...data }
      delete this.searchdata.createDate
      if (data.createDate) {
        this.searchdata.releaseEndTime = this.dateFormate(data.createDate[1])
        this.searchdata.releaseBeginTime = this.dateFormate(data.createDate[0])
      }
      request('/api/order/order/getOrderInfoByUserId', 'POST', this.searchdata).then((res) => {
        if (res.code === '200') {
          this.tableData = res.data.ipage.records
          this.paginationData = { pageSize: this.pageSize, total: res.data.ipage.total }
        }
      })
    },
    dateFormate: function (data) {
      return dayjs(data).format('YYYY-MM-DD')
    },
    pageChange (data) {
      this.searchdata.pageNum = data
      this.searchdata.pageSize = this.pageSize
      request('/api/order/order/getOrderInfoByUserId', 'POST', this.searchdata).then((res) => {
        if (res.code === '200') {
          this.tableData = res.data.ipage.records
          this.paginationData = { pageSize: this.pageSize, total: res.data.ipage.total }
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
@import '@/assets/css/tablePadding.scss';
</style>
