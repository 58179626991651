import md5 from 'js-md5'
export var SearchList = [
  { labe: '采购订单号', code: 'buyOrderCode', type: 'input' },
  { labe: '公司名称', code: 'companyName', type: 'input', placeholder: '编码/名称' },
  { labe: '供应商', code: 'providerCode', type: 'input', placeholder: '编码/名称' },
  { labe: '采购员', code: 'buyer', type: 'input' },
  {
    labe: '订单状态',
    code: 'orderStatus',
    type: 'select',
    option: [
      { label: '全部', value: '' },
      { label: '待确认', value: '1' },
      { label: '已确认', value: '2' }
    ]
  },
  {
    labe: '相关合同',
    code: 'contractCode',
    type: 'input'
  },
  {
    labe: '发布日期',
    code: 'createDate',
    type: 'daterange'
  }
]
export var supSearchList = [
  { labe: '采购订单号', code: 'buyOrderCode', type: 'input' },
  { labe: '公司名称', code: 'companyName', type: 'input', placeholder: '编码/名称' },
  {
    labe: '订单状态',
    code: 'orderStatus',
    type: 'select',
    option: [
      { label: '全部', value: '' },
      { label: '待确认', value: '1' },
      { label: '已确认', value: '2' }
    ]
  },
  { labe: '销售订单号', code: 'sellOrderCode', type: 'input' },
  { labe: '采购员', code: 'buyer', type: 'input' },
  {
    labe: '相关合同',
    code: 'contractCode',
    type: 'input'
  },
  {
    labe: '发布日期',
    code: 'createDate',
    type: 'daterange'
  }
]
export var SearchData = {
  buyOrderCode: '',
  companyName: '',
  providerCode: '',
  orderStatus: '',
  sellOrderCode: '',
  contractCode: '',
  releaseEndTime: '',
  releaseBeginTime: '',
  buyer: ''
}

export var tableField = [
  { label: '采购订单号', code: 'orderCode', type: 'input', width: '' },
  {
    label: '公司名称',
    code: 'companyName',
    type: 'function',
    width: '120px',
    handle (index, row) {
      return `${row.companyCode}-${row.companyName}`
    }
  },
  { label: '采购员 ', code: 'buyerName', type: 'input', width: '' },
  {
    label: '供应商名称',
    code: 'providerName',
    type: 'function',
    width: '140px',
    handle (index, row) {
      return `${row.providerCode}-${row.providerName}`
    }
  },
  {
    label: '订单状态',
    code: 'orderStatus',
    type: 'function',
    width: '',
    handle: (index, data) => {
      const color = md5(data.orderStatus + '').substring(0, 6)
      const label = data.orderStatus === 1 ? '待确认' : '已确认'
      const tblab = `<div class="dotlab"><div class="dot" style="background:#${color}"></div>${label}</div>`
      return tblab
    }
  },
  { label: '订单创建日期 ', code: 'produceDate', type: 'input', width: '130' },
  { label: '付款条件 ', code: 'paymentName', type: 'input', width: '' },
  {
    label: '相关合同',
    code: 'contractCode',
    width: '',
    type: 'vnode',
    handle: function (scope) {
      if (scope) {
        return {
          methods: {
            clk: function () {
              var userinfo = this.$store.state.loginInfo
              if (userinfo.userType === 1) {
                this.$router.push({ params: { viewName: 'ContractSign' }, query: { orderCode: scope.row.contractCode, id: scope.row.id } })
              } else if (userinfo.userType !== 1) {
                this.$router.push({ params: { viewName: 'ConfirmationContract' }, query: { orderCode: scope.row.contractCode, id: scope.row.id } })
              }
            }
          },
          template: '<span><el-button size="mini" type="text" @click="clk">' + scope.row.contractCode + '</el-button></span>'
        }
      }
    }
  },
  {
    label: '操作',
    code: 'act',
    width: '',
    type: 'action',
    actionData: [
      {
        actionLabel: '查看',
        action: 'tableAction',
        operationButton: 'see'
      }

    ]
  }
]
export var suptableField = [
  { label: '采购订单号', code: 'orderCode', type: 'input', width: '' },
  {
    label: '公司名称',
    code: 'companyName',
    type: 'function',
    width: '120px',
    handle (index, row) {
      return `${row.companyCode}-${row.companyName}`
    }
  },
  { label: '采购员 ', code: 'buyerName', type: 'input', width: '' },
  {
    label: '订单状态',
    code: 'orderStatus',
    type: 'function',
    width: '',
    handle: (index, data) => {
      const color = md5(data.orderStatus + '').substring(0, 6)
      const label = data.orderStatus === 1 ? '待确认' : '已确认'
      const tblab = `<div class="dotlab"><div class="dot" style="background:#${color}"></div>${label}</div>`
      return tblab
    }
  },
  { label: '订单创建日期 ', code: 'produceDate', type: 'input', width: '' },
  { label: '付款条件 ', code: 'paymentName', type: 'input', width: '' },
  { label: '销售订单号', code: 'sellCode', type: 'input', width: '' },
  {
    label: '相关合同',
    code: 'contractCode',
    width: '',
    type: 'vnode',
    handle: function (scope) {
      if (scope) {
        return {
          methods: {
            clk: function () {
              var userinfo = this.$store.state.loginInfo
              if (userinfo.userType === 1) {
                this.$router.push({ params: { viewName: 'ContractSign' }, query: { orderCode: scope.row.contractCode, id: scope.row.id } })
              } else if (userinfo.userType !== 1) {
                this.$router.push({ params: { viewName: 'ConfirmationContract' }, query: { orderCode: scope.row.contractCode, id: scope.row.id } })
              }
            }
          },
          template: '<span><el-button size="mini" type="text" @click="clk">' + scope.row.contractCode + '</el-button></span>'
        }
      }
    }
  },
  {
    label: '操作',
    code: 'act',
    width: '',
    type: 'action',
    actionData: [
      {
        actionLabel: '查看',
        action: 'tableAction',
        operationButton: 'see'
      }

    ]
  }
]
export var tableData = []
